import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material';
import * as ui from './App.styled';
import { mealViewerTheme } from './theme';
import useMediaQuery from '@mui/material/useMediaQuery';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <ThemeProvider theme={mealViewerTheme}>
      <ui.Main>
      <ui.Header>
        <ui.MVLogo />
      </ui.Header>
      <ui.Content>
        <ui.ContentSection>
            <ui.ParentStudentsTitle />
            <ui.ParentStudentsText />
            <ui.ViewMenusButton />
            <ui.SchoolAdminFooter>
              <ui.ContentFooterOr />
              <ui.ContentFooterCreateProfile />
          </ui.SchoolAdminFooter>
        </ui.ContentSection>
        <ui.DividerSection>
        {!isMobile && <ui.ContentDividerVertical />}
        {isMobile && <ui.ContentDividerHorizontal /> }
        </ui.DividerSection>
        <ui.ContentSection>
          <ui.SchoolAdminTitle />
          <ui.SchoolAdminText />
          <ui.ManageMenusButton />
          <ui.SchoolAdminFooter>
            <ui.ContentFooterOr />
            <ui.ContentFooterLearnMore />
          </ui.SchoolAdminFooter>
        </ui.ContentSection>
      </ui.Content>
      <ui.Footer>
        <ui.FooterContact />
        <ui.FooterMiddleText />
        <ui.FooterBottomText>
          <ui.FooterTermsOfUseLink />
          <ui.FooterSpacer />
          <ui.FooterPrivacyLink />
        </ui.FooterBottomText>
      </ui.Footer>
    </ui.Main>
    </ThemeProvider>
  );
}

export default App;
