import { Box, Divider, Link, Typography, styled } from "@mui/material";

export const Main = styled(Box)(({ theme }) => ({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: 'url(/imgs/mv_main_bg_2.jpg)',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
}));

export const Header = styled(Box)(({ theme }) => ({
    padding: theme.spacing(6),

    [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        padding: theme.spacing(2),
    }
}));

export const MVLogo = styled('img')(({ theme }) => ({

}));

MVLogo.defaultProps = {
    src: '/imgs/mv-logo.svg',
};

export const Content = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: theme.spacing(6),
    overflow: 'visible',
    overflowX: 'hidden',
    alignItems: 'stretch',
    minHeight: '700px',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(0),
        marginBottom: theme.spacing(2),
    }
}));

export const ContentSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    overflow: 'visible',
}));

export const DividerSection = styled(ContentSection)(({ theme }) => ({
    flexDirection: 'row',
    flex: 0.25,
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
    }
}));

export const Footer = styled(Box)(({ theme }) => ({
    backgroundColor: '#383d42',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(2),
    }
}));

export const FooterContact = styled(Box)(({ theme }) => ({
    color: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '22px',
    fontWeight: 300,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
    }
}));

FooterContact.defaultProps = {
    children: `© ${new Date().getFullYear()} Heartland Payment Systems, a Global Payments company (NYSE: GPN). All rights reserved.`,
};

export const FooterBottomText = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 300,
    paddingTop: '0.5rem',
}));

export const FooterTopText = styled(Typography)(({ theme }) => ({
    fontSize: '.875rem',
    fontWeight: 300,
    paddingRight: theme.spacing(0.5),
    lineHeight: '1.5rem',
}));

FooterTopText.defaultProps = {
    children: `© ${new Date().getFullYear()} Heartland Payment Systems, a Global Payments company (NYSE: GPN). All rights reserved.`
}

export const FooterMiddleText = styled(Typography)(({ theme }) => ({
    fontSize: '.875rem',
    fontWeight: 300,
    paddingRight: theme.spacing(0.5),
    lineHeight: '1.5rem',
    color: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        paddingTop: '0.5rem',
    }
}));

FooterMiddleText.defaultProps = {
    children: `Heartland Payment Systems Inc. is a registered ISO of Wells Fargo Bank, N.A., Concord, CA, and The Bancorp Bank, Philadelphia, PA.`
}

export const FooterSpacer = styled(Typography)(({ theme }) => ({
    color: '#ccc',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
}));

FooterSpacer.defaultProps = {
    children: '|',
};

export const FooterPrivacyLink = styled(Link)(({ theme }) => ({
    color: 'white',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
    }
}));

FooterPrivacyLink.defaultProps = {
    underline: 'none',
    children: 'Privacy Policy',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://login.myschoolbucks.com/users/etc/getprivacy',
};

export const FooterTermsOfUseLink = styled(Link)(({ theme }) => ({
    color: 'white',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
    }
}));

FooterTermsOfUseLink.defaultProps = {
    underline: 'none',
    children: 'Terms of Use',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://login.myschoolbucks.com/users/etc/getterms',
};

export const ParentStudentsTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2.25rem',
    textAlign: 'center',
    color: '#ffffff',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
    }
}));

ParentStudentsTitle.defaultProps = {
    variant: 'h3',
    children: 'Parents & Students'
};

export const ParentStudentsText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: '#ffffff',
    display: 'flex',
    paddingTop: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    }
}));

ParentStudentsText.defaultProps = {
    variant: 'body',
    children: `View your student's school cafeteria menus.`
};

export const SchoolAdminTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2.25rem',
    textAlign: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
    }
}));

SchoolAdminTitle.defaultProps = {
    variant: 'h3',
    children: 'School Admin',
    color: '#ffffff',
};

export const SchoolAdminText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: '#ffffff',
    display: 'flex',
    paddingTop: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    }

}));

SchoolAdminText.defaultProps = {
    variant: 'body',
    children: 'Manage your school menus and digital signage.'
};

export const LinkButton = styled(Link)(({ theme }) => ({
    color: 'white',
    background: 'rgb(29,183,200)',
    '&:hover': {
        background: 'rgb(31,201,220)',
    },
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '1000px',
    textDecoration: 'none',
    cursor: 'pointer',
}));

export const ViewMenusButton = styled(LinkButton)(({ theme }) => ({

}));

ViewMenusButton.defaultProps = {
    children: 'View Menus',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://schools.mealviewer.com',
}

export const ManageMenusButton = styled(LinkButton)(({ theme }) => ({

}));

ManageMenusButton.defaultProps = {
    children: 'Manage Menus',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://dashboard.mealviewer.com',
};

export const ContentFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
}));

ContentFooter.defaultProps = {
    
}

export const ContentFooterLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#1a9fb2',
}));

export const ContentFooterCreateProfile = styled(Link)(({ theme }) => ({
    fontWeight: 600,
    display: 'inline-flex',
}));

ContentFooterCreateProfile.defaultProps = {
    underline: 'none',
    color: '#1a9fb2',
    children: 'create your free profile',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://schools.mealviewer.com/onboarding',
};

export const ContentFooterLearnMore = styled(Link)(({ theme }) => ({
    fontWeight: 600,
    display: 'inline-flex',
}));

ContentFooterLearnMore.defaultProps = {
    underline: 'none',
    color: '#1a9fb2',
    children: 'learn more about MealViewer',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://www.heartlandschoolsolutions.com/nutrition-technology/mealviewer',
}

export const ContentFooterOr = styled(Typography)(({ theme }) => ({
    color: 'white',
    display: 'inline',
    paddingRight: theme.spacing(0.5)
}));

ContentFooterOr.defaultProps = {
    children: 'or',
}

export const SchoolAdminFooter = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(3),
}));

export const ContentDividerVertical = styled(Divider)(({ theme }) => ({
    borderColor: 'rgba(255, 255, 255, 1)',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
    marginTop: '50%',
    marginBottom: '50%',
}));

ContentDividerVertical.defaultProps = {
    flexItem: true,
    variant: 'middle',
    orientation: 'vertical'
};

export const ContentDividerHorizontal = styled(Divider)(({ theme }) => ({
    borderColor: 'rgba(255, 255, 255, 1)',
    marginLeft: '25%',
    marginRight: '25%',
}));

ContentDividerHorizontal.defaultProps = {
    flexItem: true,
    variant: 'middle',
    orientation: 'horizontal',
};